// Copyright 2021
// Polus

import * as React from 'react';

type FeaturePageContainerPropsType = {
  children: React.ReactNode;
};

const FeaturePageContainer = ({ children }: FeaturePageContainerPropsType) => (
  <section className="flex flex-col items-center space-y-16 md:px-4">{children}</section>
);

export default FeaturePageContainer;
