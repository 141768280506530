// Copyright 2021
// Polus
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Callout from '../../components/Callout';
import FeaturePageContainer from '../../components/features/FeaturePageContainer';
import ImageWithTextSection from '../../components/features/ImageWithTextSection';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';

const Import = (): React.ReactElement => (
  <Layout>
    <Seo title="Import existing flashcards" />
    <FeaturePageContainer>
      {/* TODO: make this a shared component */}
      <div className="flex flex-col items-center justify-around space-y-8 space-x-0 md:flex-row md:space-y-0 md:space-x-8">
        <div className="w-full max-w-[28rem]">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            height="320"
            src="https://www.youtube.com/embed/cMT4XrPZ7go"
            title="YouTube video player"
            width="100%"
            allowFullScreen
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="mb-3 text-2xl font-bold md:text-4xl">Import existing cards</h1>
          <p>
            If you've ever created virtual flashcards, chances are you are using existing apps to
            study them. Studius helps you quickly import cards from Quizlet, Anki, Google Docs,
            Microsoft Word or any other text files.
          </p>
        </div>
      </div>

      <ImageWithTextSection
        content="Create a new deck on Studius and copy/paste existing cards from any text source. For Quizlet-specific instructions, watch the video above. "
        header="Copy from anywhere"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            quality={100}
            src="../../images/import/copy-and-paste.png"
          />
        }
      />

      <ImageWithTextSection
        content="Choose the symbol that separates prompt and answer, and a card from another. Studius will automatically generate a preview to show you what the cards will look like after importing them. When you're ready, import them in one click."
        header="Preview before importing"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            quality={100}
            src="../../images/import/preview.png"
          />
        }
      />

      <Callout label="Convinced? Use Studius to study flashcards from Quizlet, Anki and others." />
    </FeaturePageContainer>
  </Layout>
);

export default Import;
