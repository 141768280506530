// Copyright 2021
// Polus

import * as React from 'react';

import { classNames } from '../utils/style';

type CalloutPropsType = {
  label: string;
  isPremium?: boolean;
};

const Callout = ({ label, isPremium = false }: CalloutPropsType) => (
  <div className="w-full">
    <a
      className={classNames(
        isPremium ? 'from-yellow-500 to-yellow-300' : 'from-indigo-400 to-blue-400',
        `flex justify-center items-center bg-gradient-to-r md:p-4 p-2 space-x-1 w-full text-white rounded-lg shadow-lg cursor-pointer`,
      )}
      href="https://app.studius.ai"
    >
      <p className="text-center text-lg font-bold">{label}</p>
    </a>
    {isPremium && (
      <p className="mt-4 w-full text-center text-sm text-gray-500">
        This is a premium feature, included with Studius Premium for $4 a month.
      </p>
    )}
  </div>
);

export default Callout;
