// Copyright 2021
// Polus

import * as React from 'react';

type ImageWithTextSectionPropsType = {
  header: string;
  content: string;
  image?: React.ReactElement;
};

const ImageWithTextSection = ({ header, content, image }: ImageWithTextSectionPropsType) => (
  <div className="flex flex-col-reverse items-center justify-around space-y-8 space-y-reverse md:flex-row md:space-y-0 md:px-4">
    <div className="md:w-1/2">
      <h2 className="mb-3 text-xl font-bold md:text-2xl">{header}</h2>
      <p>{content}</p>
    </div>
    <div className="mx-auto flex max-h-[18rem] max-w-[18rem] items-center justify-center overflow-hidden rounded-md border-2 shadow-sm">
      {image}
    </div>
  </div>
);

export default ImageWithTextSection;
